<template>
  <div
    :class="$style.root"
  >
    <i18nLink
      :to="{ name: 'index' }"
      :class="$style.link"
    >
      <span
        v-html="logo"
      />
    </i18nLink>

    <span
      v-html="city"
      :class="$style.city"
    />

    <span
      v-html="dates"
      :class="$style.dates"
    />
  </div>
</template>

<script setup>
const logo = 'Manifesta&hairsp;15'
const dates = '08.09 — 24.11.2024'
const city = 'Barcelona Metropolitana'
</script>

<style module lang=scss>
  .root {
    composes: font-size-small from global;

    display: flex;
    align-items: flex-start;
    gap: calc(var(--unit--horizontal) * 3);

    overflow: hidden;

    white-space: nowrap;
  }

  .link {
    composes: reset-link from global;
    text-transform: uppercase;
    font-feature-settings: "dlig" 1;

    white-space: nowrap;
  }

  .button {
    composes: reset-button from global;
  }

  .dates {
    @media (max-width: $breakpoint-m) {
      display: none;
    }
  }

  .city {
    @media (max-width: $breakpoint-l) {
      display: none;
    }
  }
</style>
