<template>
  <LayoutPillComponent
    view-option="primary"
    componentType="link"
    :to="link?.to"
    :locale="link?.locale"
  >
    {{ link?.label }}
  </LayoutPillComponent>
</template>

<script setup>
const { locale: currentLocale } = useI18n()

const link = computed(() => {
  const object = {
    en: {
      label: 'GET YOUR TICKET',
      to: {
        name: 'slug',
        params: { slug: 'tickets' }
      },
      locale: 'en'
    },
    es: {
      label: 'COMPRA TU ENTRADA',
      to: {
        name: 'slug',
        params: { slug: 'entradas' }
      },
      locale: 'es'
    },
    ca: {
      label: 'COMPRA L\'ENTRADA',
      to: {
        name: 'slug',
        params: { slug: 'entrades' }
      },
      locale: 'ca'
    },
  }

  return object[currentLocale.value]
})
</script>
